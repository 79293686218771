// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-style {
  position: sticky;
  bottom: 0px;
  display: flex;
  left: 0px;
  width: 100%;
  height: 5em;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(233, 238, 240, var(--tw-bg-opacity));
}

.date-container {
  display: flex;
  flex: 1 1;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.year-text {
  font-weight: 300;
}

.copyright-text {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(233, 83, 30, var(--tw-text-opacity));
}

.logos-container {
  display: flex;
  height: 100%;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.version-container {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,0DAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,oBAAA;EACA,+CAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;AACJ","sourcesContent":[".footer-style {\n    position: sticky;\n    bottom: 0px;\n    display: flex;\n    left: 0px;\n    width: 100%;\n    height: 5em;\n    overflow: hidden;\n    --tw-bg-opacity: 1;\n    background-color: rgb(233 238 240 / var(--tw-bg-opacity));\n}\n\n.date-container {\n    display: flex;\n    flex: 1 1 0%;\n    align-items: center;\n    gap: 0.5rem;\n    white-space: nowrap;\n}\n\n.year-text {\n    font-weight: 300; \n}\n\n.copyright-text {\n    font-weight: 700;\n    --tw-text-opacity: 1;\n    color: rgb(233 83 30 / var(--tw-text-opacity));\n}\n\n.logos-container {\n    display: flex;\n    height: 100%;\n    flex: 1 1 0%;\n    align-items: center;\n    justify-content: center;\n    gap: 0.75rem;\n}\n\n.version-container {\n    display: flex;\n    flex: 1 1 0%;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 0.5rem;\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
