import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { IconXMark, IconOk, IconEditPencil } from 'utils/icon-centrocontrol'
import { deviceName, areArraysEqualEms } from 'utils/Utils'
import "./styleEms.scss"

export const TableEmsScada = ({ devicesEms, modeEms, setNewArray, edit, setEdit, setDisabledApply }) => {
    const [data, setData] = useState(JSON.parse(JSON.stringify(devicesEms)))
    const header = ["Orden", "Nombre", "Tipo dispositivo", "Salida", "Habilitado"]
    const [disabled, setDisabled] = useState(true)

    const handleEdit = () => {
        setEdit(!edit)
    }

    const EnableDevice = (numserie, id_entry) => {
        let copyEms = JSON.parse(JSON.stringify(devicesEms))
        setData(prevData => {
            const filterDevice = copyEms?.filter(dev => dev?.numserie === numserie && dev?.id_entry === id_entry)
            let updatedData = prevData?.map(item => {
                if (item?.numserie === numserie && item?.id_entry === id_entry) {
                    if (item?.mode === modeEms) {
                        return { ...item, mode: filterDevice?.[0]?.mode !== modeEms ? filterDevice?.[0]?.mode : 0, cont_order: item?.cont_order }
                    } else {
                        return { ...item, mode: modeEms }
                    }
                }
                return item
            })
            let orderCounter = 1
            updatedData = updatedData?.map(item => {
                if (item?.mode === modeEms) {
                    return { ...item, cont_order: orderCounter++ }
                } else {
                    const itemFind = copyEms?.find(dev => dev?.numserie === item?.numserie && dev?.id_entry === item?.id_entry)
                    return { ...item, cont_order: itemFind?.cont_order }
                }
            })
            return updatedData
        })
    }

    const reorder = (startIndex, endIndex) => {
        const result = Array.from(data)
        const [removed] = result?.splice(startIndex, 1)
        removed.mode = modeEms
        result?.splice(endIndex, 0, removed)
        let maxOrder = 1
        result?.forEach((item) => {
            if (item?.mode === modeEms) {
                item.cont_order = maxOrder++
            }
        })
        return result
    }

    const onDragEnd = (result) => {
        const { source, destination } = result
        if (!destination) {
            return
        }
        const update = reorder(source?.index, destination?.index)
        setData(update)
    }

    const orderArray = (array) => {
        const ModeEms = array?.filter(obj => obj?.mode === modeEms)
        const noModeEms = array?.filter(obj => obj?.mode !== modeEms)
        ModeEms?.sort((a, b) => a?.cont_order - b?.cont_order)
        return [...ModeEms, ...noModeEms]
    }

    const handleResetTable = () => {
        let copyEms = JSON.parse(JSON.stringify(devicesEms))
        setData(orderArray(copyEms))
    }

    useEffect(() => {
        let copyEms = JSON.parse(JSON.stringify(devicesEms))
        setData(orderArray(copyEms))
    }, [])

    const newArrayData = () => {
        setNewArray([])
        data?.forEach(dev =>
            setNewArray(prev => [...prev, { numserie: dev?.numserie, nick: dev?.nick, id_tipo_dispositivo: dev?.id_tipo_dispositivo, id_parent: dev?.id_padre, ID_entry: dev?.id_entry, mode: dev?.mode, control_order: dev?.cont_order }]))
    }

    useEffect(() => {
        newArrayData()
    }, [data, devicesEms])

    useEffect(() => {
        setDisabled(areArraysEqualEms(data, devicesEms))
        setDisabledApply(areArraysEqualEms(data, devicesEms))
    }, [data, devicesEms])

    return (
        <div className="containter-ems-table">
            <Grid item xs={12} className="title-button-ems-drag-table">
                <h4 className='ml-0'>Orden de Control:</h4>
                <span className="container-button-header-table-Scada">
                    <ButtonStyled onClick={() => handleResetTable()} className="button-with-text" disabled={disabled} > <p>Resetear tabla</p></ButtonStyled>
                    <ButtonStyledIcon className='boton-edit-alarm' title='Editar' onClick={() => { handleEdit() }}>
                        <IconEditPencil className='boton-edit-hijo' />
                    </ButtonStyledIcon>
                </span>
            </Grid>
            <TableContainer className="container-table-cc-ems" component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                        <TableRow>
                            {header && header?.map(head => {
                                return (
                                    <StyledTableCellHead>{head}</StyledTableCellHead>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEnd} isDisabled={true} onDragStart={false}>
                        <Droppable droppableId={"dndTableBody"}>
                            {(provided) => (
                                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                    {data && data.map((item, index) => {
                                        return (
                                            <Draggable
                                                isDragDisabled={!edit}
                                                draggableId={`${item?.id_entry}-${item?.numserie}`}
                                                index={index}
                                                key={`${item?.id_entry}-${item?.numserie}`}>
                                                {(provided, snapshot) => (
                                                    <TableRow
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`table-row
                                                        } ${snapshot.isDragging ? "row-dragging" : ""}`}>
                                                        <TableCell style={{ minWidth: `${Math.floor(50 / 5)}vw`, padding: "15px 0px" }} align="center">{item?.mode === modeEms ? item?.cont_order : ''}</TableCell>
                                                        <TableCell style={{ minWidth: `${Math.floor(50 / 5)}vw`, padding: "15px 0px" }} align="center">{item?.nick}</TableCell>
                                                        <TableCell style={{ minWidth: `${Math.floor(50 / 5)}vw`, padding: "15px 0px" }} align="center">{deviceName(item?.id_tipo_dispositivo)}</TableCell>
                                                        <TableCell style={{ minWidth: `${Math.floor(50 / 5)}vw`, padding: "15px 0px" }} align="center">{item?.alias_output !== '' ? item?.alias_output : item?.name_output}</TableCell>
                                                        <TableCell style={{ minWidth: `${Math.floor(50 / 5)}vw`, padding: "15px 0px" }} align="center" className="center-table-ems">
                                                            {item?.mode === modeEms && !edit && <IconOk />}
                                                            {item?.mode !== modeEms && !edit && <IconXMark />}
                                                            {edit && <FormControlLabel
                                                                className="checkbox-display-group checkbox path checkInstallations-check checkbox-display-group flex"
                                                                control={
                                                                    <input
                                                                        disabled={!edit}
                                                                        type="checkbox"
                                                                        style={{
                                                                            margin: "0 auto",
                                                                            background: "#fff",
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            stroke: item?.enable ? "#FF8A1F" : "#fff",
                                                                        }}
                                                                        checked={item?.mode === modeEms ? true : false}
                                                                        onChange={() => EnableDevice(item?.numserie, item?.id_entry)}
                                                                    />
                                                                }
                                                            />}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                </TableBody>
                            )}
                        </Droppable >
                    </DragDropContext >
                </Table >
            </TableContainer >
        </div>
    )
}