import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { Card, Grid, Table } from "@mui/material"
import { headersScada } from "services/headers"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { DeviceDate } from "utils/DeviceDate/DeviceDate"
import { IconWarning } from "utils/icon-centrocontrol"
import { toKiloW } from "utils/functionsforGraphs"
import { urls, monofasic } from "utils/constant"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { CardTriphasic, SinglePhasic } from "views/Scada/Consumption/CardTableScada"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import "./StyledCardScada.scss"

export const CardDevicesScada = ({ devices, masterData, keySwitch, refresh, setRefresh, setActiveTab, setKeySwitch, loose, currentDevice, setCurrentDevice, setActiveTabDevice, loading, setLoading, selected }) => {
    const token = sessionStorage?.token
    const location = useLocation()
    const numserieMaster = location.pathname.split('/')[4]
    const [data, setData] = useState(selected ? {} : null)
    const loadingSlice = useSelector((state) => state.loading.name)

    const fetchData = () => {
        setLoading(true)
        let url
        if (numserieMaster && loose === 0) {
            url = `getCurrentData/${devices?.numserie}?idCCMaster=${numserieMaster}`
        } else {
            url = `getCurrentData/${devices?.numserie}`
        }
        fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => {
                if (data && data?.currentData?.numserie === null) {
                    data.currentData.numserie = devices?.numserie
                }
                if ((data?.currentData?.intensidad_magneto >= 0 && data?.currentData?.intensidad_magneto !== null) && (data?.currentData?.potencia_contratada >= 0 && data?.currentData?.potencia_contratada !== null)) {
                    data.currentData.error = 0
                } else {
                    data.currentData.error = 1
                }
                setData(data)
                if (selected) {
                    setCurrentDevice(data)
                }
            })
            .catch(err => {
                console.error("ERROR. API Scada", err)
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [devices?.numserie, keySwitch, loadingSlice])

    useEffect(() => {
        if (selected) {
            setActiveTabDevice(devices)
            setCurrentDevice(data)
        }
    }, [devices])
    
    return (
        <Grid constainer className="card-scada-container">
            <Card
                className={`card-box-alt custom-hover card-scada-card ${currentDevice?.currentData?.numserie === devices?.numserie && "card-scada-selected"}`}
                onClick={() => { setActiveTabDevice(devices); setActiveTab(0); setCurrentDevice(data); setKeySwitch(Date.now()) }}
            >
                <Grid item sm={12} lg={12} xl={12} className="flex-center justify-start height-2-5">
                    <div>
                        <DropDownDevices
                        scada={true}
                        setLoading={setLoading}
                        device={devices}
                        masterPermission={masterData}
                        active={true}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        loose={loose}
                        instalacion={devices}
                        masterPermissionNew={masterData.permissions}
                        />
                    </div>

                    <div className="card-scada-image">
                        <ImageDevice id_type_device={devices?.id_tipo_dispositivo} />
                    </div>
                
                    <Grid container alignItems="flex-start">
                        <Grid item xs={12} sm={6} lg={10} xl={10}>
                        {data && data?.currentData?.nick?.length > 15 ? (
                            <HtmlTooltip title={<span>{data?.currentData?.nick}</span>}>
                            <p className="card-scada-nick">{data?.currentData?.nick.substr(0, 14) + '...'}</p>
                            </HtmlTooltip>
                        ) : (
                            <p className="card-scada-nick">{data?.currentData?.nick}</p>
                        )}
                        </Grid>

                        {data && data?.currentData?.error === 1 && ( 
                        <Grid item xs={12} sm={6} lg={2} xl={2} className="warning-icon-container">
                            <HtmlTooltip title={<span>Revise su configuración.</span>}>
                            <IconWarning width={20} height={20} />
                            </HtmlTooltip>
                        </Grid>
                         )} 
                    </Grid>
                </Grid>
                
                <div className="divider card-scada-divider" />
                <Grid item sm={12} lg={12} xl={12} className="card-scada-device-date">
                    <DeviceDate
                        device={devices}
                        loading={loading}
                        data={data?.currentData}
                        masterPermission={devices}
                    />
                </Grid>
                <Grid className="card-scada-container-data">
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>Potencia total : {toKiloW(data?.currentData?.pact)} kW</h4>
                    </Grid>
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>E. Importada : {toKiloW(data?.currentData?.eaconsdia)} kWh</h4>
                    </Grid>
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>E. Exportada : {toKiloW(data?.currentData?.eagendia)} kWh </h4>
                    </Grid>
                    <Table className="card-scada-containter-table">
                        <thead className="border-bottom-div" >
                            <tr>
                                <th className="card-scada-containter-table-cell-l">{' '}</th>
                                <th className="card-scada-containter-table-cell">P(kW)</th>
                                <th className="card-scada-containter-table-cell">I(A)</th>
                                <th className="card-scada-containter-table-cell">V(V)</th>
                                <th className="card-scada-containter-table-cell"> PF</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '13px' }}>
                            {monofasic?.includes(devices?.id_tipo_dispositivo) ?
                                <SinglePhasic data={data} />
                            :
                                <CardTriphasic data={data} />
                            }
                        </tbody>
                    </Table>
                </Grid >
            </Card >
        </Grid>
    )
}