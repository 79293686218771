import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { DragDropContext } from '@hello-pangea/dnd'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { GlobalContext } from 'context/GlobalContext'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import Maps from 'components/Mapa/MapShow'
import { headersCentroControl, headersScada } from 'services/headers'
import { devicesMinervaConsumption, dispositivos, urls } from 'utils/constant'
import { getIconComponent, deviceStatus, getUniqueID, initialColumns } from 'utils/Utils'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import DropContainer from 'views/LayOut/DropContainer'
import { AuthFooter } from 'views/Footer/AuthFooter'
import { NavBar } from "views/NavBar/NavBar"
import { Alarms } from 'views/Alarms/Alarms'
import { CardDevicesScada } from '../../../components/CardScada/CardDevicesScada'
import { MenuScada } from '../../../components/MenuScada/MenuScada'
import { ScadaData } from './ScadaData'
import { OnOff } from '../CcMaster/tabs/OnOff'
import "./StyleConsumption.scss"
 
export const Consumption = () => {
    const token = sessionStorage?.token
    const navigate = useNavigate()
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const isMobile = useMediaQuery({ maxWidth: 1680 })
    const { actualProject, setUpdateDevice, updateDevice } = useContext(GlobalContext)
    const [activeTab, setActiveTab] = useState(0)
    const [selfConsumption, setSelfConsumption] = useState('general')
    const [loading, setLoading] = useState(true)
    const [loadingDevice, setLoadingDevice] = useState(true)
    const [loadingCard, setLoadingCard] = useState(true)
    const [activeTabCuadro, setActiveTabCuadro] = useState(false)
    const [devices, setDevices] = useState()
    const [data, setData] = useState([])
    const [activeTabDevice, setActiveTabDevice] = useState(null)
    const [currentDevice, setCurrentDevice] = useState(null)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [loose, setLoose] = useState()
    const [masterError, setMasterError] = useState()
    const [columns, setColumns] = useState(deviceStatus)
   
    const relayKeys = Object?.keys(columns)?.filter(key => key?.startsWith('Relay'))
    const isSelected = currentDevice === null && activeTab === 0
    const loadingSlice = useSelector((state) => state.loading.name)
   
    const fetchIdCcMaster = (numserie) => {
        fetch(urls.ccontrol + `type/${numserie}`, { method: "GET", headers: headersCentroControl(token) })
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                navigate(`/auth/login`)
            } else {
                return []
            }
        })
        .then((data) => {
            setLoose(data)
        })
        .catch((err) => {
            console.error("ERROR. API de Centro de control", err)
        })
    }
 
    const fetchInfoPower = (loose) => {
        let url = loose !== 0 && loose !== 20 ? `${idCcMaster}` : `${idCcMaster}?idCCMaster=${idCcMaster}`
        fetch(urls.scada + `getInfoPower/` + url, { method: "GET", headers: headersScada(token) })
        .then((res) => {
            return res.json()
        })
        .then((data) => {
            setMasterError(data)
        })
        .catch((err) => {
            console.error("ERROR. API Scada", err)
        })
    }
 
    const getLayout = async ({ numserie }) => {
    
        try {
            const res = await fetch(`${urls.scada}distributionPanel/${numserie}`, {
                method: "GET",
                headers: headersScada(token)
              })
              if (res.ok) {
       
                let data = await res.text()
                let configuration
       
                if (data === "Panel de distribución sin configuración") {
                    configuration = []
                } else {
                    try {
                        data = JSON.parse(data)
                        if (data?.confPanel) {
                            configuration = JSON.parse(data.confPanel)
                        } else {
                            configuration = []
                        }
                    } catch (error) {
                        console.error("Error al parsear el JSON:", error)
                        configuration = []
                    }
                }
 
                const initial = {
                    Devices: {
                        name: "Devices",
                        items: devices
                    },
                    Relay1: {
                        name: "Relay1",
                        columns: initialColumns("Relay1")
                    }
                }
 
        configuration?.forEach((relay, index) => {
          const relayId = `Relay${index + 1}`
          const columns = initialColumns(relayId)
     
          relay?.forEach((nestedArray, positionIndex) => {
              if (nestedArray === null) {
                  columns[positionIndex].items = []
              } else {
                columns[positionIndex].items = nestedArray?.map(iconType => {
                    const { device_type, icon_type, name, numserie, content, isMoved } = getIconComponent(
                        iconType.icon_type,
                        iconType.name,        
                        iconType.numserie    
                    )
                    return {
                        id: getUniqueID(),
                        device_type,
                        icon_type,
                        name,
                        numserie,
                        content,
                        isMoved
                    }
                  })
              }
          })
 
          initial[relayId] = {
              name: relayId,
              columns: columns
          }
      })
     
      setColumns(initial)

          } else {
            console.error("Error en la solicitud")
          }
        } catch (error) {
            console.error("Error en la solicitud", error)
        }
    }
 
    const fetchDevices = (idCCMaster) => {
        let url
        if (loose === 0) {
            url = `getccm/${idCCMaster}?idCCMaster=${idCCMaster}`
        } else if (loose > 0) {
            url = `getccm/${idCCMaster}`
        }
        fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
        .then(res => res.json())
        .then(data => {
            setDevices({
                ...data,
                permissions: actualProject?.permissions,
                error: masterError ? masterError : [],
                loose: loose
            })
        })
        .catch(err => console.error("ERROR. API Scada", err))
        .finally(() => {
            setLoading(false)
        })
    }
 
    const filtrarObjetos = (objeto) => {
        let resultado = []
        if (objeto?.childrenDevices && objeto?.childrenDevices.length > 0) {
            objeto.childrenDevices.forEach(element => {
                let hijo = []
                if (element?.childrenDevices && element?.childrenDevices.length > 0) {
                    hijo = filtrarObjetos(element)
                }
                resultado.push(element, ...hijo)
            })
        }
        return resultado
    }
 
    const handleClick = () => {
        setActiveTab(2)
        setActiveTabCuadro(true)
    }
   
    useEffect(() => {
        fetchIdCcMaster(idCcMaster)
    }, [idCcMaster])
 
    useEffect(() => {
        if (loose !== undefined) {
            fetchInfoPower(loose)
        }
    }, [loose, loadingSlice])
 
    useEffect(() => {
        if (loose !== undefined && actualProject?.permissions) {
            fetchDevices(idCcMaster)
        }
    }, [actualProject, loose, keySwitch])
 
    useEffect(() => {
        if (devices) {
            const mapaDispositivos = dispositivos.ccmaster === devices?.id_tipo_dispositivo ? [] : [devices]
            const mapDevice = mapaDispositivos?.concat(filtrarObjetos(devices))
            const result = mapDevice?.filter(dev => devicesMinervaConsumption?.includes(dev?.id_tipo_dispositivo))
            setData(result)
        }
    }, [devices])
 
    useEffect(() => {
        if (idCcMaster && isSelected) {
            getLayout({ numserie: idCcMaster })
        }
    }, [idCcMaster, activeTab, currentDevice])
 
    return (
        <>
            <Maps places={[]} zoom={5} className="display-none" />
            <div className="container-scada-principal">
                <NavBar
                    setRefresh={setUpdateDevice}
                    selfConsumption={selfConsumption}
                    setSelfConsumption={setSelfConsumption} />
                {loading || devices === undefined ?
                    <Loader />
                :
                    <>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="pl-3 pt-2 navbar-scada scada-navbar-centro-control"
                        >
                            <MenuScada
                                token={token}
                                activeTabCuadro={activeTabCuadro}
                                setActiveTabCuadro={setActiveTabCuadro}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                setCurrentDevice={setCurrentDevice}
                                master={devices}
                                masterError={masterError}
                                loose={loose}
                            />
                        </Grid>
                        <Grid container className="container-scada-functions">
                            <Grid item xs={isSelected ? 12 : 2} className={`mb-3 ${isSelected && "containter-scada-graphs-center"}`}>
                                {data && data?.filter(ele => ele?.activo)?.map((dev, index) => {
                                    return (
                                        <Grid item xs={isSelected ? 2 : 12}>
                                            <CardDevicesScada
                                                selected={data.length === 1} setActiveTab={setActiveTab} key={index} setKeySwitch={setKeySwitch} keySwitch={keySwitch}
                                                activeTabDevice={activeTabDevice} setActiveTabDevice={setActiveTabDevice} masterData={devices}
                                                devices={dev} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} loading={loadingCard} setLoading={setLoadingCard}
                                                loose={loose} refresh={updateDevice} setRefresh={setUpdateDevice} permissions={devices.permissions}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={isSelected ? 12 : 10} className="container-scada-functions container-scada-dnd mb-2">
                                {isSelected && !loadingCard &&
                                    <Grid item xs={12} className="mx-6">
                                        <div className="flex-end mt-8 ">
                                            <ButtonStyled className="boton-delete-alarm mb-3" onClick={handleClick}>
                                                <span className="p-1">Configurar cuadro</span>
                                            </ButtonStyled>
                                        </div>
                                        <DragDropContext>
                                            <div className="flex flex-col relay-main-container">
                                                <div>
                                                    {relayKeys?.map((relayKey) => (
                                                        <div key={relayKey} className="relay-container flex-col">
                                                            {columns[relayKey]?.columns?.map((column) => (
                                                            <DropContainer key={column.id} columnId={column.id} column={column} direction="horizontal"disabled={true} />
                                                            ))}
                                                        </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </DragDropContext>
                                    </Grid>
                                }
                                {activeTab === 0 && currentDevice !== null &&
                                    <ScadaData currentDevice={currentDevice} data={activeTabDevice} loading={loadingDevice} setLoading={setLoadingDevice} loadingCard={loadingCard} loose={loose} />}
                                {activeTab === 1 &&
                                    <Alarms
                                        instalacioneSeleccionadas={[idCcMaster]}
                                        dispositivos={[devices]} />}
                                {activeTab === 2 &&
                                    <ConfiguracionList
                                        activeTabCuadro={activeTabCuadro}
                                        consumption={true}
                                        scadaSelf={true}
                                        instalacioneSeleccionadas={[idCcMaster]}
                                        dispositivo={[devices]}
                                        scada={true}
                                        masterError={masterError}
                                    />
                                }
                                {activeTab === 4 && <OnOff activeTab={activeTab} isMobile={isMobile} />}
                            </Grid>
                        </Grid>
                    </>
                }
            </div>
            <AuthFooter />
        </>
    )
}